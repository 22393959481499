<template>
    <el-container class="conbox">
        <!-- <el-aside  :width="isCollapse ? '70px' : '200px' "> -->
        <el-aside width="240px">
            <!-- <div class="toggle-button" @click="toggleCollapse" >|||</div> -->
            <el-menu background-color="#fff"
                     text-color="#333"
                     active-text-color="#007BFF"
                     unique-opened
                     :collapse="isCollapse"
                     :collapse-transition="false"
                     :router="true"
                     :default-active="$route.path"
                     class="left_nav">

                <el-menu-item index="/toBeSignedOrder">
                    <template slot="title" style="color:#333">
                        <i class="el-icon-document-copy"></i>
                        <span>{{td("集运订单列表")}}</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/SimpleIn">
                    <template slot="title" style="color:#333">
                        <i class="el-icon-edit-outline"></i>
                        <span>{{td("集运订单录入")}}</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/Inventory">
                    <template slot="title" style="color:#333">
                        <i class="el-icon-box"></i>
                        <span>{{td("包裹列表")}}</span>
                    </template>
                </el-menu-item>
            </el-menu>
        </el-aside>
        <el-main style="background-color:#F1F3FF;padding:0 24px">
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
    export default {
        name: "TMS",
        data() {
            return {
                isCollapse: false,
            }
        },
        // methods: {
        //点击按钮切换菜单的折叠与展开
        //   toggleCollapse() {
        //     this.isCollapse = !this.isCollapse;
        //   },
        // }
    };
</script>

<style scoped>
    .conbox {
        height: calc(100vh - 70px);
    }

    .el-aside {
        font-size: 14px;
        background-color: #fff;
        height: 100%;
    }

    .el-menu {
        border-right: none;
    }

    .el-menu span {
        font-size: 18px;
    }

    .toggle-button {
        background-color: #29384B;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }

    .el-menu-item.is-active {
        border-right: #007BFF solid 4px !important;
        background-color: #F5FAFF !important;
        color: #007BFF !important;
    }
</style>
